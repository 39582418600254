<template>
  <div class="main">
    <div class="box-top"></div>
    <div class="box">
      <div class="main-title-box-p">
        <div class="main-title-box ">
          <div class="top">
            <div class="main-title">Sourcing Quotations</div>
          </div>

          <div class="first">
            <div class="user">First time user?</div>
            <div class="view search-button" @click="showInstructionFun()">
              &nbsp; View instructions.
            </div>
          </div>

          <div class="instruction" v-if="showInstruction">
            <div class="step1 step-item">
              <div class="step-top">
                <div class="icon">
                  <svg
                    width="30"
                    height="36"
                    viewBox="0 0 30 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="14.7471"
                      cy="18.4346"
                      r="14.7471"
                      fill="#F57C00"
                      fill-opacity="0.3"
                    />
                    <path
                      d="M16.0065 11.4V24H13.6665V13.362H11.0025V11.4H16.0065Z"
                      fill="#F57F45"
                    />
                  </svg>
                </div>
                <div class="word">Enter product details</div>
              </div>
              <div class="pic first-pic">
<!--                <img src="@/assets/sourcing/quotation-1.png" alt="" />-->
              </div>
            </div>
            <div class="step-item step-line">
              <svg
                width="196"
                height="1"
                viewBox="0 0 196 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="-4.37114e-08"
                  y1="0.5"
                  x2="195.399"
                  y2="0.499983"
                  stroke="#FCD8B3"
                />
              </svg>
            </div>
            <div class="step2 step-item">
              <div class="step-top">
                <div class="icon">
                  <svg
                    width="30"
                    height="36"
                    viewBox="0 0 30 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="14.7472"
                      cy="18.4346"
                      r="14.7471"
                      fill="#F57C00"
                      fill-opacity="0.3"
                    />
                    <path
                      d="M18.966 22.02V24H9.67798V22.434L14.682 17.682C15.246 17.142 15.624 16.674 15.816 16.278C16.02 15.87 16.122 15.468 16.122 15.072C16.122 14.484 15.924 14.034 15.528 13.722C15.132 13.41 14.55 13.254 13.782 13.254C12.498 13.254 11.514 13.692 10.83 14.568L9.19198 13.308C9.68398 12.648 10.344 12.138 11.172 11.778C12.012 11.406 12.948 11.22 13.98 11.22C15.348 11.22 16.44 11.544 17.256 12.192C18.072 12.84 18.48 13.722 18.48 14.838C18.48 15.522 18.336 16.164 18.048 16.764C17.76 17.364 17.208 18.048 16.392 18.816L13.026 22.02H18.966Z"
                      fill="#F57F45"
                    />
                  </svg>
                </div>
                <div class="word">Submit your quote</div>
              </div>
              <div class="pic">
<!--                <img src="@/assets/sourcing/quotation-2.png" alt="" />-->
              </div>
            </div>
            <div class="step-item step-line">
              <svg
                width="196"
                height="1"
                viewBox="0 0 196 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="-4.37114e-08"
                  y1="0.5"
                  x2="195.399"
                  y2="0.499983"
                  stroke="#FCD8B3"
                />
              </svg>
            </div>
            <div class="step3 step-item">
              <div class="step-top">
                <div class="icon">
                  <svg
                    width="30"
                    height="36"
                    viewBox="0 0 30 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="14.7471"
                      cy="18.4346"
                      r="14.7471"
                      fill="#F57C00"
                      fill-opacity="0.3"
                    />
                    <path
                      d="M15.2039 16.602C16.3679 16.746 17.2559 17.142 17.8679 17.79C18.4799 18.438 18.7859 19.248 18.7859 20.22C18.7859 20.952 18.5999 21.618 18.2279 22.218C17.8559 22.818 17.2919 23.298 16.5359 23.658C15.7919 24.006 14.8799 24.18 13.7999 24.18C12.8999 24.18 12.0299 24.054 11.1899 23.802C10.3619 23.538 9.65391 23.178 9.06591 22.722L10.0739 20.904C10.5299 21.288 11.0819 21.594 11.7299 21.822C12.3899 22.038 13.0679 22.146 13.7639 22.146C14.5919 22.146 15.2399 21.978 15.7079 21.642C16.1879 21.294 16.4279 20.826 16.4279 20.238C16.4279 19.65 16.1999 19.194 15.7439 18.87C15.2999 18.534 14.6159 18.366 13.6919 18.366H12.5399V16.764L15.4019 13.362H9.67791V11.4H18.2639V12.966L15.2039 16.602Z"
                      fill="#F57F45"
                    />
                  </svg>
                </div>
                <div class="word">Connect directly with buyers</div>
              </div>
              <div class="pic">
<!--                <img src="@/assets/sourcing/quotation-3.png" alt="" />-->
              </div>
            </div>
          </div>

          <div
            class="hide-instruction search-button"
            v-if="showInstruction"
            @click="showInstructionFun()"
          >
            <svg
              width="21"
              height="12"
              viewBox="0 0 21 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4375 2L18.875 10.4375"
                stroke="#828282"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.4375 2L2 10.4375"
                stroke="#828282"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="box-m"></div>
      <div class=" main-box">
        <div class="cards">
          <div>
            <div class="search-top-menu">
              <div
                class="item"
                v-for="(val, index) in checkValueArr"
                :key="index"
              >
                <div
                  :class="[
                    'value',
                    'search-button',
                    { 'value-active': val.checkValue == checkValue }
                  ]"
                  @click="changeCheckValue(val.checkValue)"
                >
                  {{ val.label }}( {{ val.value }} )
                </div>
                <div class="line" v-if="val.checkValue == checkValue"></div>
              </div>
            </div>
            <div class="border"></div>
            <div class="search">
              <div class="left">
                <div class="search-icon">
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.5913 24.228L19.8912 17.7994C21.5013 15.9448 22.3042 13.566 22.1328 11.1578C21.9615 8.7497 20.8292 6.49765 18.9714 4.87017C17.1136 3.24268 14.6734 2.36507 12.1585 2.4199C9.64347 2.47473 7.24733 3.45778 5.46851 5.16455C3.68969 6.87132 2.66514 9.17039 2.60799 11.5835C2.55085 13.9966 3.46551 16.338 5.1617 18.1205C6.85789 19.903 9.20502 20.9895 11.7148 21.1539C14.2246 21.3182 16.7038 20.5479 18.6367 19.003L25.3368 25.4317L26.5913 24.228ZM4.41157 11.8117C4.41157 10.2964 4.87986 8.8152 5.75723 7.55531C6.6346 6.29542 7.88164 5.31346 9.34065 4.7336C10.7997 4.15374 12.4051 4.00202 13.954 4.29763C15.5029 4.59324 16.9256 5.3229 18.0423 6.39435C19.159 7.46579 19.9194 8.8309 20.2275 10.317C20.5356 11.8032 20.3775 13.3436 19.7731 14.7435C19.1688 16.1434 18.1454 17.3399 16.8323 18.1818C15.5192 19.0236 13.9755 19.4729 12.3963 19.4729C10.2793 19.4707 8.24973 18.6628 6.75282 17.2265C5.25591 15.7902 4.41392 13.8429 4.41157 11.8117Z"
                      fill="#545454"
                    />
                  </svg>
                </div>
                <div class="search-input">
                  <input
                    type="text"
                    placeholder="Search..."
                    @keydown.enter="getSourcings"
                    v-model="filter.product_name"
                  />
                </div>
              </div>
              <div class="all" v-if="role != ''">
                <div class="all-search  search-button" @click="showMenu">
                  <div class="name">{{ allMenu }}</div>
                  <div class="icon">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 18 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.719 0H15.9612C15.8417 0 15.7292 0.0585939 15.6589 0.154688L9.0003 9.33281L2.3417 0.154688C2.27139 0.0585939 2.15889 0 2.03936 0H0.281547C0.129203 0 0.0401406 0.173438 0.129203 0.297657L8.39327 11.6906C8.69327 12.1031 9.30733 12.1031 9.60498 11.6906L17.869 0.297657C17.9605 0.173438 17.8714 0 17.719 0V0Z"
                        fill="#515A6E"
                      />
                    </svg>
                  </div>
                </div>
                <div class="all-menu search-button" v-if="showAllMenu">
                  <div class="item" @click="changeAllMenu('All')">All</div>
                  <div class="item" @click="changeAllMenu('My inquiries')">
                    My inquiries
                  </div>
                  <div class="item" @click="changeAllMenu('Involved')">
                    Involved
                  </div>
                </div>
              </div>
            </div>
            <div class="list-table">
              <div class="title">
                <!--                <div class="item product font-medium">Company</div>-->
                <div class="item attendee font-medium">Product Name</div>
                <div class="item size font-medium">Quality Standard</div>
                <div class="item date font-medium">Quantity</div>
                <div class="item size font-medium status-col">Status</div>
                <div class="item date font-medium">Created Date</div>
              </div>
              <div class="content">
                <div class="loading-pic" v-if="loading">
                  <img src="@/assets/loading.gif" />
                </div>
                <div
                  v-else
                  v-for="(item, index) in list"
                  :key="index"
                  :class="['item', { active: activeIndex === item.id }]"
                  @mouseover="companyMouseOver(item.id)"
                  @mouseleave="companyMouseLeave()"
                >
                  <div class="top search-button" @click="view(item)">
                    <div class="attendee font-medium">
                      {{ item.product_name || "--" }}
                    </div>
                    <div class="size font-medium">
                      {{ item.quality_standard || "--" }}
                    </div>
                    <div class="date font-medium">
                      {{ item.quantity || "--" }}
                    </div>
                    <div class="status-col font-medium">
                      <div
                        class="status status-progress"
                        v-if="item.status == 1"
                      >
                        In progress
                      </div>
                      <div
                        class="status status-expired"
                        v-if="item.status == 3"
                      >
                        Expired
                      </div>
                      <div
                        class="status status-completed"
                        v-if="item.status == 2"
                      >
                        Completed
                      </div>
                    </div>
                    <div class="date font-medium">
                      {{ item.created_at | moment }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="empty" v-if="list.length === 0 && !loading">
              No Data
            </div>
            <div class="page">
              <Page
                transfer
                :page-size="limit"
                :total="total"
                show-elevator
                show-total
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopLogin ref="popLogin"></PopLogin>
    <PopQuotation
      :inquiry="inquiry"
      ref="popInquiry"
      @getSourcings="getSourcings"
    ></PopQuotation>
    <editQuotation
      :isEditInquiry="isEditInquiry"
      ref="editQuotation"
      @getSourcings="getSourcings"
    ></editQuotation>
    <div class="box-m"></div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { uniq, trim } from "lodash";
import moment from "moment";
import PopLogin from "@/components/popLoginMobile";
import PopQuotation from "@/components/quotationMobile";
import EditQuotation from "@/components/editQuotationMobile";

const { _querySourcing, _querySourcingType } = api;

export default {
  name: "products",
  components: {
    PopLogin,
    PopQuotation,
    EditQuotation
  },
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      loginPop: false,
      confLoading: false,
      showInquire: false,
      inquireForm: {
        product_name: "-"
      },
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      industry_usage_grade: [
        "Food Grade",
        "Feed Grade",
        "Industrial Grade",
        "Cosmetic Grade"
      ],
      industry_usage_grade_temp: "",
      compendial_standard: ["USP", "FCC", "JP", "CP"],
      compendial_standard_temp: "",
      curItem: {},
      modType: "card",
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,
      sourcingCol: [
        {
          type: "index",
          width: 60,
          align: "center"
        },
        {
          title: "COMPANY",
          render: (h, params) => {
            return h(
              "span",
              (params.row.company &&
                params.row.company.company_name.substring(0, 3) + "*****") ||
                "--"
            );
          }
        },
        {
          title: "PRODUCT NAME",
          width: 320,
          key: "product_name"
        },
        {
          title: "QUANTITY",
          width: 200,
          render: (h, params) => {
            return h("span", params.row.quantity || "--");
          }
        },
        {
          title: "QUALITY STANDARD",
          width: 250,
          render: (h, params) => {
            return h("span", params.row.quality_standard || "--");
          }
        },
        // {
        //   title: "STATUS",
        //   width: 160,
        //   slot: "status"
        // },
        {
          title: "Action",
          width: 100,
          align: "center",
          slot: "action"
        }
      ],
      cateList: [],
      countryList: [],
      filter: {
        product_name: ""
      },
      prices: ["CIF", "FOB", "Delivered", "Others"],
      price_type: "",
      justShowInquire: false,
      activeIndex: 0,
      showInstruction: false,
      allNum: 0,
      progressNum: 0,
      completedNum: 0,
      expiredNum: 0,
      checkValue: 0,
      checkValueArr: [
        { label: "All", checkValue: 0, value: 0 },
        { label: "In progress", checkValue: 1, value: 0 },
        { label: "Completed", checkValue: 2, value: 0 },
        { label: "Expired", checkValue: 3, value: 0 }
      ],
      inquiry: {},
      showInquiry: false,
      showAllMenu: false,
      allMenu: "All",
      isEditInquiry: false
    };
  },
  created() {
    this.getSourcings();
  },
  filters: {
    moment(val) {
      return moment
        .utc(val)
        .local()
        .fromNow();
    }
  },
  methods: {
    changeAllMenu(val) {
      this.allMenu = val;
      this.showAllMenu = false;
      if ("All" == val) {
        this.filter.user_id = 0;
        this.filter.involved = 0;
      }
      if ("My inquiries" == val) {
        this.filter.user_id = 1;
        this.filter.involved = 0;
      }
      if ("Involved" == val) {
        this.filter.user_id = 0;
        this.filter.involved = 1;
      }
      this.getSourcings();
    },
    showMenu() {
      this.showAllMenu = !this.showAllMenu;
    },
    view(item) {
      this.$refs.popInquiry.showInquiry = true;
      this.inquiry = item;
    },
    changeCheckValue(val) {
      this.checkValue = val;
      this.filter.status = val;
      this.getSourcings();
    },
    login() {
      if (this.role === "") {
        this.$refs.popLogin.showLogin = true;
        return;
      } else {
        this.$refs.editQuotation.showInquiry = true;
        return;
      }
    },
    jumpTo(path) {
      if (this.role === "") {
        this.$router.push(
          `/user-action/login?redirect=${this.$route.fullPath}`
        );
        return;
      }
      this.$router.push(path);
    },
    showInstructionFun() {
      this.showInstruction = !this.showInstruction;
    },
    companyMouseOver(e) {
      this.activeIndex = e;
    },
    companyMouseLeave() {
      this.activeIndex = null;
    },
    clearSearch() {
      this.page = 1;
      this.curBar = "";
      this.filter.product_name = "";
      this.getSourcings();
    },

    handleSearch() {
      this.page = 1;
      this.getSourcings();
    },

    pageChange(page) {
      this.page = page;
      this.getSourcings();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getSourcings();
    },
    getSourcings() {
      this.loading = true;
      this.list = [];
      _querySourcing({
        page: this.page,
        limit: this.limit,
        ...this.filter,
        type: "quotation"
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.getSourcingType();
          this.loading = false;
        });
    },
    getSourcingType() {
      _querySourcingType({ type: "quotation" }).then(response => {
        const { data } = response;
        this.checkValueArr = data;
      });
    },
    delIt(item) {
      this.delPop = true;
      this.curItem = item;
    },
    addIt() {
      this.showInquire = true;
      this.inquireForm.product_name = "";
    },
    editIt(item) {
      this.showInquire = true;
      this.curItem = item;
      this.inquireForm.product_name = item.product_name;
      this.inquireForm.product_type = item.product_type;
      this.inquireForm.country = item.country;
      this.inquireForm.state = item.state;
      this.inquireForm.industry_usage_grade = item.industry_usage_grade;
      this.inquireForm.compendial_standard = item.compendial_standard;

      // industry_usage_grade
      var copyIndustry = JSON.parse(
        JSON.stringify([
          "Food Grade",
          "Feed Grade",
          "Industrial Grade",
          "Cosmetic Grade"
        ])
      );
      if (item.industry_usage_grade) {
        var i = 0;
        item.industry_usage_grade.forEach(ite => {
          if (copyIndustry.indexOf(ite) < 0) {
            i++;
            copyIndustry.push(ite);
            this.industry_usage_grade = copyIndustry;
          }

          if (i === 0) {
            this.industry_usage_grade = copyIndustry;
          }
        });
      } else {
        this.industry_usage_grade = copyIndustry;
      }

      // compendial_standard
      var copyCompendial = JSON.parse(
        JSON.stringify(["USP", "FCC", "JP", "CP"])
      );
      if (item.compendial_standard) {
        var j = 0;
        item.compendial_standard.forEach(ite => {
          if (copyCompendial.indexOf(ite) < 0) {
            j++;
            copyCompendial.push(ite);
            this.compendial_standard = copyCompendial;
          }

          if (j === 0) {
            this.compendial_standard = copyCompendial;
          }
        });
      } else {
        this.compendial_standard = copyCompendial;
      }
    },
    addCheckBox(key) {
      const value = trim(this[`${key}_temp`]);
      if (value) {
        const arr = uniq([...this[key], value]);
        this[key] = arr;
        this.industry_usage_grade_temp = "";
        this.compendial_standard_temp = "";
      }
    },
    cancel() {
      this.loading = false;
      this.handleReset("form");
      this.industry_usage_grade_temp = "";
      this.compendial_standard_temp = "";
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    showIt(item) {
      this.showInquire = false;
      this.justShowInquire = true;
      this.curItem = item;
      this.inquireForm.product_name = item.product_name;
      this.inquireForm.product_type = item.product_type;
      this.inquireForm.country = item.country;
      this.inquireForm.state = item.state;
      this.inquireForm.industry_usage_grade = item.industry_usage_grade;
      this.inquireForm.compendial_standard = item.compendial_standard;
      this.inquireForm.quantity = item.quantity;
      this.inquireForm.quality_standard = item.quality_standard;
      this.inquireForm.application = item.application;
      this.inquireForm.main_specs = item.main_specs;
      this.inquireForm.price_basis = item.price_basis;
      this.inquireForm.price_basis_value = item.price_basis_value;
      this.inquireForm.packaging = item.packaging;
      this.inquireForm.shipment_date = item.shipment_date;
      this.inquireForm.price_idea = item.price_idea;
      this.inquireForm.required_documents = item.required_documents;
      this.inquireForm.note = item.note;
      this.inquireForm.fob_basis = item.fob_basis;
      this.inquireForm.price_indication = item.price_indication;
      this.inquireForm.shipment_date = item.shipment_date;
      this.inquireForm.spec = item.spec;
      this.inquireForm.price_indication = item.price_indication;
      this.inquireForm.packaging = item.packaging;
      this.price_type = item.price_basis ? item.price_basis : "";
    }
  }
};
</script>

<style lang="less" scoped>
.sc-width {
  /*background: #ffffff;*/
}
.box {
  margin: 34px auto 0 auto;
  background: #f2f2f2;
  .main-box {
    background: #fff;
    border-radius: 15px;
  }
}
.box-m {
  height: 24px;
  background: #f2f2f2;
}
.main-title-box {
  /*display: flex;*/
  align-items: center;
  justify-content: space-between;
  /*background: #fff;*/
  padding: 25px;
  /*border-bottom: 1px solid #ccc;*/
  margin: 0 auto;
  /*padding-top: 65px;*/
  padding-left: 10px;
  padding-right: 10px;
  .top {
    display: flex;
    justify-content: space-between;
  }
  .main-title {
    font-weight: 600;
    font-size: 23px;
    line-height: 37px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .exhibitor-sourcing {
    display: flex;
    justify-content: space-between;
  }
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .my-sourcing {
    /*width: 200px;*/
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Primary */

    color: #f57f45;
  }
  .add-sourcing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;
    background: #f57f45;
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #ffffff;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }

  .first {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 22px;
    .user {
    }
    .view {
      color: #f57f45;
      margin-left: 5px;
    }
  }
  .instruction {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    flex-wrap: wrap;
    .step-item {
      .step-top {
        display: flex;
        .icon {
        }
        .word {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;

          /* Gray 2 */

          color: #ffffff;
          margin-left: 12px;
          position: relative;
          bottom: 2px;
        }
      }
      .pic {
        margin-top: 10px;
      }
      .first-pic {
        margin-top: 40px;
      }
    }
    .step-line {
    }
  }
  .hide-instruction {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}

.search-box {
  display: flex;
  align-items: center;
  /*border-radius: 50px;*/
  height: 35px;
  overflow: hidden;
  width: 45%;
  margin: 20px;
  .input-box {
    position: relative;
    flex: 1;
    height: 100%;
    background: #fff;
    line-height: initial;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
    /*border: 1px solid #dfdfdf;*/
    /*border-right: none;*/
    /*border-top-left-radius: 40px;*/
    /*border-bottom-left-radius: 40px;*/
    border: 1px solid #ef7f21;
    border-radius: 5px;
    padding-left: 3px;
    padding-right: 3px;
    .search-icon {
      position: absolute;
      top: 7px;
      left: 10px;
      font-size: 20px;
      color: #888;
    }
    input {
      width: 100%;
      height: 100%;
      outline: none;
      background: transparent;
      border: none;
      color: #444;
      caret-color: #888;

      &::-webkit-input-placeholder {
        color: #bdcada;
      }
      &:-moz-placeholder {
        color: #bdcada;
      }
      &::-moz-placeholder {
        color: #bdcada;
      }
      &:-ms-input-placeholder {
        color: #bdcada;
      }
    }
  }
  .btn {
    cursor: pointer;
    background: #fff;
    color: @primary-color;
    border-radius: 5px;
    /*display: flex;*/
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    margin-left: 20px;
    background: #ef7f21;
    font-size: 18px;

    font-weight: 500;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    /*padding-top: 9px;*/
    width: 120px;
    height: 35px;
  }
  .my-sourcing {
    width: 200px;
    height: 30px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  .clearbtn {
    margin-left: 10px;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 20px;
  }
  .type-wrap {
    position: absolute;
    right: 20px;
    color: #444;
    font-size: 12px;
    text-align: right;
    .des {
      display: block;
    }
    .num {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

// /deep/ .list-table .ivu-table-body .ivu-btn {
//   color: @primary-color;
//   border: 1px solid @primary-color;
// }

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .search-input {
    width: 400px;
  }
}
.main {
  background: #f2f2f2;
  .box-top {
    height: 27px;
  }
}
.cards {
  .table-title {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #000000;
    margin-bottom: 36px;
  }
}
.box {
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box {
    margin-top: 206px;
    .empty {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .cards {
      min-height: 300px;
      position: relative;

      .list-table {
        list-style: none;
        li {
          margin-bottom: 10px;
          background-color: #fff;
          border: 1px solid #f2f2f2;
          box-shadow: 0 0 4px #f8f8f8;
          .header {
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            .wrap1 {
              min-width: 80%;
              cursor: pointer;
              text-align: left;
              .title {
                font-size: 14px;
              }
            }
            .wrap2 {
              text-align: right;
              .status {
                display: inline-block;
                background-color: #ddd;
                padding: 2px 8px;
                margin-bottom: 4px;
                &.pend {
                  background-color: #ddd;
                  color: #333;
                }
                &.acc {
                  background-color: #d5ffd2;
                  color: #418d3a;
                }
                &.rej {
                  background-color: #ffddd9;
                  color: #c04c3a;
                }
              }
              .time {
                color: #888;
              }
            }
          }
          .content {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #f2f2f2;
            .con {
              padding: 10px;
              .tit {
                color: #666;
                padding-bottom: 6px;
              }
              .tip {
                font-size: 12px;
              }
              .doc {
                color: #ff6600;
                a {
                  color: #ff6600;
                }
              }
            }
            .c1 {
              cursor: pointer;
            }
            .c1,
            .c2,
            .c3 {
              flex: 0 0 20%;
            }
            .c4 {
              flex: 0 0 40%;
            }
          }
        }
        .footer {
          padding: 10px;
          text-align: right;
          .numb {
            text-align: left;
            padding: 4px 10px;
            display: inline-block;
            background-color: #ffe7d6;
          }
        }
      }
      .page {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }

      .ivu-poptip-body {
        h4 {
          font-size: 12px;
          text-align: left;
          color: #444;
          padding: 4px 0;
        }
        .tags {
          flex-wrap: wrap;
          display: flex;
          p {
            text-align: left;
            color: #999;
            flex: 0 0 50%;
            padding: 4px 0;
          }
        }
      }
    }
  }
  .list-table {
    .title {
      display: flex;
      height: 42px;
      background: #f2f2f2;
      border-radius: 5px;
      justify-content: space-between;
      font-size: 18px;

      font-weight: 500;
      color: #757575;
      line-height: 19px;
      .item {
        font-size: 16px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 40px;
      }
      .product {
        width: 400px;
      }
      .attendee {
        width: 300px;
      }
      .size {
        width: 300px;
      }
      .status-col {
        width: 150px;
      }
      .date {
        width: 200px;
      }
      .document {
        width: 300px;
      }
    }
    .content {
      /*display: flex;*/
      .item {
        height: 60px;
        /*padding:0 30px;*/
        .top {
          display: flex;
          height: 60px;
          justify-content: space-between;
          border-bottom: 1px solid #ececec;
          div {
            display: flex;
            justify-content: left;
            align-items: center;
          }
          /*padding-top: 30px;*/
          /*div {*/
          /*  font-size: 16px;*/
          /*  */
          /*  font-weight: 500;*/
          /*  color: #323538;*/
          /*  line-height: 19px;*/
          /*  padding-left: 20px;*/
          /*}*/
          .size {
            padding-left: 40px;
          }
          .status-col {
            width: 150px;
            padding-left: 40px;
          }
          .product {
            width: 400px;
            .main-name {
            }
            .by-name {
              font-size: 12px;

              font-weight: 400;
              color: #999999;
              line-height: 19px;
            }
          }
          .attendee {
            width: 300px;
            padding-left: 40px;
          }
          .size {
            width: 300px;
            padding-left: 40px;
          }
          .date {
            width: 200px;
            padding-left: 40px;
          }
          .document {
            width: 300px;
          }
        }
        .divided {
          /*width: 1440px;*/
          height: 1px;
          background: #cccccc;
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
        }
      }
      .active {
        background: #f2f0f9;
      }
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.browse {
  cursor: pointer;
  color: #ef842d;
  padding-right: 20px;
}
.search-top-menu {
  display: flex;
  padding: 40px 0 10px 40px;
  .item {
    margin-right: 25px;
    .value {
      font-size: 18px;
    }
    .value-active {
      color: #f57f45;
      font-weight: 600;
    }
    .line {
      height: 2px;
      background-color: #f57f45;
      position: relative;
      top: 11px;
    }
  }
}
.border {
  border-top: 1px solid #ececec;
}
.status {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center !important;
  color: #fff;
  font-size: 13px;
  align-items: center;
}
.status-progress {
  background: #11828f;
}
.status-expired {
  background: #c0399f;
}
.status-completed {
  background: #925acf;
}
.search {
  display: flex;
  padding-left: 7px;
  border: none;
  justify-content: space-between;
  .left {
    display: flex;
    .search-icon {
      position: relative;
      left: 35px;
      top: 8px;
    }
    .search-input {
      input {
        background: #f2f2f2;
        outline: none;
        border-radius: 10px;
        border: none;
        width: 280px;
        height: 40px;
        padding-left: 45px;
      }
      input:focus {
        border: 1px solid #f57f45;
        background: #fff;
      }
    }
  }
  .all {
    .all-search {
      display: flex;
      padding-right: 40px;
      align-items: center;
      .name {
        padding-right: 8px;
      }
    }
    .all-menu {
      background: #fff;
      z-index: 999;
      position: absolute;
      width: 160px;
      margin-top: 10px;
      padding: 10px;
      .item {
        margin-bottom: 5px;
        height: 35px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 5px;
      }
      .item:hover {
        background: #f2f0f9;
      }
    }
  }
}
.loading-pic {
  margin-top: 40px;
  img {
    width: 60px;
    height: 60px;
  }
  text-align: center;
}
</style>
<style lang="less" scoped>
.ivu-table thead {
  background: linear-gradient(90deg, #a25d72, #2d3b81);
  opacity: 0.9;
}
.ivu-table th {
  background: none;
  font-size: 18px;

  font-weight: 500;
  color: #fefefe;
  line-height: 19px;
}
.list-table {
  width: 100%;
}
.main-box {
  margin: 0 auto;
  overflow-x: auto;
  width: 1200px;
}
.main-title-box-p {
  margin: 0 auto;
  background: #3b3951;
}
</style>
